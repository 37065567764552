<script setup>
import { onClickOutside } from '@vueuse/core'

const props = defineProps({
  openPopup: {
    type: Boolean,
    default: false
  }
});
const emit = defineEmits(['update:openPopup']);

const modalPopup = ref(null)
const isVisible = ref(props.openPopup);

onMounted(() => {
  isVisible.value = props.openPopup;
});

watch(() => props.openPopup, (newVal) => {
  isVisible.value = newVal;
});

onClickOutside(modalPopup, () => {
  closeModal();
});

const closeModal = () => {
  isVisible.value = false;
  emit('update:openPopup', false);
}
</script>

<template>
  <div v-if="isVisible" class="fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50 z-[100]">
    <div ref="modalPopup" class="bg-white rounded-lg shadow-lg p-6 relative">
      <slot />
      <div class="w-11 h-11 rounded-full absolute flex justify-center items-center -top-3 -right-3 bg-white shadow-box cursor-pointer" @click="closeModal">
        <IconCloseLight class="w-6 h-6 hover:w-8 hover:h-8 transition-all" />
      </div>
    </div>
  </div>
  </template>

